import { useState, useEffect, PropsWithChildren } from 'react'
import { Badge, Button, Row, Drawer } from 'antd'
import Icon from '@common/Icon'
import useModal from '@hooks/useModal'
import { api } from '@helpers/api'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'
import { ModalContext } from '@contexts/ModalContext'
import { ClockCircleOutlined } from '@ant-design/icons'
import { ITicket } from '@/types/ITicket'
import { useGenericContext } from '@hooks/useGenericContext'
import { UserContext, useUser } from '@contexts/UserContext'
import { IRequestList } from '@/types/ICollection'
import { AssignedContext } from '@contexts/AssignedContext'
import AssignTicketsList from '../AssignTicketsList'

const statuses = 'NEW,PROCESSING_ATTRIBUTED,PROCESSING_PLANNED,PENDING'

const queryTicketsAssignedtoUser = async (uuid: string, page: number) => {
  const url = `ticket?assignedTo=${uuid}&status=${statuses}&sort=-updated_at&page=${page}&perPage=5`
  return await api.get(url)
}

const useTicketsDrawer = () => {
  const { user } = useUser()
  const { open, onClose, onOpen } = useModal()
  const [page, setPage] = useState(1)
  const { setLoading, setData, setCount } = useGenericContext(AssignedContext)

  const getTickets = async (current = 1) => {
    setLoading(true)
    await queryTicketsAssignedtoUser(user.uuid, current)
      .then(e => {
        setData(e.data)
        setCount(e.data.meta.total)
        setLoading(false)
        setPage(current)
      })
      .catch(() => setTimeout(getTickets, 2000))
  }
  const openDrawer = () => {
    setLoading(true)
    getTickets(1).then(() => onOpen())
  }
  return { getTickets, page, open, onClose, onOpen: openDrawer }
}

const TicketDrawerTitle = ({ getTickets }: { getTickets: () => void }) => {
  const { t } = useTranslation()
  return (
    <Row justify={'space-between'}>
      {t('YOURTICKETS')}
      <button
        className="fa-solid fa-arrows-retweet"
        onClick={() => getTickets()}
      />
    </Row>
  )
}

const TicketsDrawerWrapper = () => {
  const { data } = useGenericContext(AssignedContext)
  const { getTickets, open, page, onClose, onOpen } = useTicketsDrawer()
  return (
    <ModalContext.Provider value={{ open, onClose, onOpen }}>
      <Drawer
        destroyOnClose
        {...{ open, onClose }}
        width={500}
        title={<TicketDrawerTitle {...{ getTickets }} />}
        placement="right"
      >
        {data ? (
          <AssignTicketsList page={page} getTickets={getTickets} />
        ) : null}
      </Drawer>
      <Button onClick={onOpen} type="link">
        <Icon color="gray" name="fal fa-clipboard-list-check" size="30px" />
      </Button>
    </ModalContext.Provider>
  )
}

const useAssignTicketsDrawer = () => {
  const { user } = useGenericContext(UserContext)
  const [data, setData] = useState<IRequestList<ITicket> | undefined>(undefined)
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(false)

  const getCount = () =>
    api.get(`ticket/count?assignedTo=${user?.uuid}`).then(e => {
      setCount(() =>
        statuses.split(',').reduce((a, b) => {
          return a + Number(e.data[b as keyof typeof e])
        }, 0)
      )
    })

  useEffect(() => {
    getCount()
    const update = setInterval(getCount, 20000)
    return () => clearInterval(update)
  }, [])

  return { loading, setLoading, data, setData, count, setCount }
}

const CountBadge = ({ children }: PropsWithChildren) => {
  const { loading, count } = useGenericContext(AssignedContext)
  const theme = useTheme()
  return (
    <Badge
      color="blue"
      count={
        loading ? <ClockCircleOutlined style={{ color: theme.icon }} /> : count
      }
    >
      {children}
    </Badge>
  )
}

const AssignTicketsDrawer = () => {
  const { loading, setLoading, data, setData, count, setCount } =
    useAssignTicketsDrawer()
  return (
    <AssignedContext.Provider
      value={{ loading, setLoading, setData, data, count, setCount }}
    >
      <CountBadge>
        <TicketsDrawerWrapper />
      </CountBadge>
    </AssignedContext.Provider>
  )
}

export default AssignTicketsDrawer
