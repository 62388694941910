import { Row, Col } from 'antd'
import { useTheme } from 'styled-components'
import ModalTicket from '@components/Ticket/Modal'
import TicketTabs from '@components/Ticket/Tabs'
import { useTranslation } from 'react-i18next'
import Icon from '@common/Icon'
import { useUser } from '@contexts/UserContext'
import Title from '@components/common/Title'
import { ReportButton, arrFilterTickets } from '@components/Ticket/Filter'

const CreateTicketButton = () => {
  const theme = useTheme()
  const { user } = useUser()
  const { t } = useTranslation()
  return (
    <Col xs={{ span: 24 }} xl={{ span: 3, offset: user.level > 1 ? 0 : 21 }}>
      <ModalTicket
        style={{ backgroundColor: theme.green, color: theme.white }}
        block
        action="create"
        children={t('CREATETICKET')}
        icon={<Icon name="fa-light fa-plus" color="white" />}
      />
    </Col>
  )
}

const Tickets = () => {
  const { t } = useTranslation()
  const { user } = useUser()

  return (
    <>
      <Title name={t('TICKETS')} />
      <Row gutter={[10, 5]} style={{ marginBottom: '8px' }}>
        {user.level > 1 ? (
          <>
            {arrFilterTickets.map(item => (
              <Col xs={{ span: 24 }} key={item.span} xl={{ span: item.span }}>
                {item.children}
              </Col>
            ))}
          </>
        ) : null}
        <CreateTicketButton />
      </Row>
      {user.level > 1 ? <ReportButton /> : null}
      <TicketTabs />
    </>
  )
}

export default Tickets
