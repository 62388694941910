import { Row, Col } from 'antd'
import Title from '@common/Title'
import ResponsiveTableCard from '@common/ResponsiveTableCard'
import { columns } from '@components/Order/Table'
import OrderDrawer from '@components/Order/OrderDrawer'
import { ActionsItems } from '@components/Order/Actions'

const Orders = () => {
  const numForm = Math.floor(Math.random() * 10000).toString()

  return (
    <Row>
      <Col xl={12} xs={24}>
        <Title name="Cotações" />
      </Col>
      <Col xl={{ span: 2, offset: 10 }} xs={24}>
        <OrderDrawer action={'create'} numForm={numForm}>
          <ActionsItems />
        </OrderDrawer>
      </Col>
      <Col span={24}>
        <ResponsiveTableCard
          style={{ width: '100%' }}
          {...{ columns, url: '/order?' }}
        />
      </Col>
    </Row>
  )
}

export default Orders
