import Actions from '../Actions'
import dayjs from 'dayjs'
import { DATEFORMAT } from '@constants/dateformat'
import type { ColumnsType } from 'antd/es/table'
import type { IOrder } from '@/types/IOrder'
import TranslateText from '@components/common/TranslateText'

export const columns: ColumnsType<IOrder> = [
  {
    title: 'ACTIONS',
    dataIndex: 'actions',
    key: 'actions',
    render: (_, data) => <Actions data={data} />
  },
  {
    title: 'COMPANY',
    dataIndex: 'company',
    key: 'company',
    render: company => <span>{company.name}</span>
  },
  {
    title: 'ORDER_DATE',
    dataIndex: 'created_at',
    key: 'created_at',
    render: created_at => dayjs(created_at).format(DATEFORMAT)
  },
  {
    title: 'TYPE',
    dataIndex: 'type',
    key: 'type',
    render: type => <TranslateText name={type} />
  },
  {
    title: 'STATUS',
    dataIndex: 'status',
    key: 'status',
    render: status => <TranslateText name={status} />
  },
  {
    title: 'TOTAL_PRICE',
    dataIndex: 'price_total',
    key: 'price_total',
    render: price_total => <span>{`R$ ${price_total}`}</span>
  },
  {
    title: 'SENT_AT',
    dataIndex: 'sent_at',
    key: 'sent_at',
    render: (sent_at: string) => (
      <>
        {sent_at !== null ? (
          <span>${dayjs(sent_at).format(DATEFORMAT)}</span>
        ) : null}
      </>
    )
  }
]
