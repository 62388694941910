import DeviceIcons from '@components/Device/Icons'
import useParamsSelect from '@hooks/useParamsSelect'
import { IRequestList } from '@/types/ICollection'
import { IDevice } from '@/types/IDevice'
import { api } from '@helpers/api'
import { Select } from 'antd'
import { useTranslation } from 'react-i18next'

const getDevices = async (search = '') => {
  return api
    .get<IRequestList<IDevice[]>>(`/device?search=${search}`)
    .then(e => e.data.data)
}
const DeviceFilterSelect = () => {
  const { options, ...props } = useParamsSelect({
    func: getDevices,
    param: 'device',
    paramsToSearch: true
  })
  const { t } = useTranslation()
  return (
    <Select
      {...props}
      allowClear
      showSearch
      placeholder={t('FILTERBYDEVICE')}
      filterOption={false}
      style={{ width: '100%' }}
      options={options?.map((device: IDevice) => ({
        value: device.uuid,
        label: (
          <>
            {device.os ? <DeviceIcons kind={device.os.kind} /> : null}
            {device.name}
          </>
        )
      }))}
    />
  )
}

export default DeviceFilterSelect
