import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { message } from 'antd'
import { api } from '@helpers/api'
import { fetchWithFeedback } from '@helpers/fetchWithFeedback'

const useForm = (
  action: string,
  close: () => void,
  url: string,
  uuid?: string
) => {
  const [messageApi, contextHolder] = message.useMessage()
  const [disabled, setDisabled] = useState(false)
  const { t } = useTranslation()

  const onFinish = async (newObject: any) => {
    setDisabled(true)
    if (action === 'edit') {
      await fetchWithFeedback(
        messageApi,
        close,
        api.put(`${url}/${uuid}`, newObject),
        t
      ).finally(() => setDisabled(false))
    } else {
      await fetchWithFeedback(
        messageApi,
        close,
        api.post(`${url}`, newObject),
        t
      ).finally(() => setDisabled(false))
    }
  }
  return { onFinish, disabled, contextHolder }
}

export default useForm
