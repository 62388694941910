import { useState, useEffect } from 'react'
import { Card, Row, Col, Skeleton } from 'antd'
import { queryListWithFilter } from '@helpers/QueryListWithFilter'
import {
  DragDropContext as Context,
  Droppable,
  Draggable
} from '@hello-pangea/dnd'
import { useTranslation } from 'react-i18next'
import { api } from '@helpers/api'
import { useTheme } from 'styled-components'
import {
  IColumn,
  PropsDelete,
  PropsSetItems,
  PropsMoveItems
} from '@/types/IGroups'
import { submitNewGroup, addGroupColumns } from '@helpers/addGroupColumns'
import GroupForm from '@components/Device/GroupForm'
import DeleteButton from '@components/Device/DeleteButton'
import useFetch from '@hooks/useFetch'

const onDragEnd = (
  result: any,
  columns: IColumn[],
  setColumns: React.Dispatch<React.SetStateAction<IColumn[]>>
) => {
  if (!result.destination) return
  moveItems(result, { columns, setColumns })
}

const moveItems = (result: any, { columns, setColumns }: PropsMoveItems) => {
  const sourceColumnId = result.source.droppableId
  const destinationColumnId = result.destination.droppableId

  if (sourceColumnId !== destinationColumnId) {
    const sourceColumn = columns.find(col => col.id === sourceColumnId)
    const destinationColumn = columns.find(
      col => col.id === destinationColumnId
    )
    const sourceItems = [...sourceColumn.items]
    const destinationItems = [...destinationColumn.items]

    const [movedItem] = sourceItems.splice(result.source.index, 1)
    destinationItems.splice(result.destination.index, 0, movedItem)

    setColumns(prevColumns =>
      prevColumns.map(col => {
        if (col.id === sourceColumnId) {
          return { ...col, items: sourceItems }
        } else if (col.id === destinationColumnId) {
          return { ...col, items: destinationItems }
        }
        return col
      })
    )
    api.put(`device/${result.draggableId}`, {
      group: destinationColumnId !== 'noGroup' ? destinationColumnId : null
    })
  }
}

const deleteGroup = async ({
  uuid,
  isSuccess,
  columns,
  data,
  setColumns,
  setDepDeleteGroup
}: PropsDelete) => {
  try {
    await api.delete(`/group/${uuid}`)
    await deleteGroupColumns(columns, uuid, setColumns, setDepDeleteGroup)
    setItemsOnColumns({ isSuccess, data, setColumns })
  } catch (error) {
    console.error('error')
  }
}

const deleteGroupColumns = async (
  columns: IColumn[],
  uuid: string,
  setColumns: any,
  setDepDeleteGroup: any
) => {
  const updatedColumns = columns.filter(column => column.id !== uuid)
  setColumns(updatedColumns)
  setDepDeleteGroup(columns)
}

const setItemsOnColumns = ({ isSuccess, data, setColumns }: PropsSetItems) => {
  if (isSuccess && data.data) {
    setColumns(prevColumns =>
      prevColumns.map(column => ({
        ...column,
        items: data.data.filter(
          device =>
            device.group?.uuid === column.id ||
            (device.group === null && column.id === 'noGroup')
        )
      }))
    )
  }
}

const Columns = ({
  isSuccess,
  columns,
  data,
  setColumns,
  setDepDeleteGroup
}: Omit<PropsDelete, 'uuid'>) => {
  const theme = useTheme()
  return (
    <>
      {columns.map(column => (
        <Col xs={24} sm={12} md={12} lg={8} xl={8} key={column.id}>
          <Droppable droppableId={column.id}>
            {provided => (
              <ul
                className="devices"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                <Card
                  title={column.title}
                  extra={
                    <DeleteButton
                      uuid={column.id}
                      onDelete={async () =>
                        await deleteGroup({
                          uuid: column.id,
                          isSuccess,
                          columns,
                          data,
                          setColumns,
                          setDepDeleteGroup
                        })
                      }
                    />
                  }
                  bordered={false}
                  styles={{
                    header: { backgroundColor: theme?.blue, color: 'white' }
                  }}
                  style={{ minHeight: '200px' }}
                >
                  {column.items.map(({ uuid, name }, index) => (
                    <Draggable key={uuid} draggableId={uuid} index={index}>
                      {provided => (
                        <li
                          key={uuid}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <Card
                            bordered={false}
                            style={{
                              marginBottom: '5px',
                              backgroundColor: theme.dark,
                              color: theme.white
                            }}
                          >
                            {name}
                          </Card>
                        </li>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Card>
              </ul>
            )}
          </Droppable>
        </Col>
      ))}
    </>
  )
}

const useDragAndDrop = () => {
  const { t } = useTranslation()
  const [columns, setColumns] = useState<IColumn[]>([
    { id: 'noGroup', title: t('NOGROUP'), items: [] }
  ])
  const [depDeleteGroup, setDepDeleteGroup] = useState<IColumn[]>(columns)
  return { depDeleteGroup, columns, setColumns, setDepDeleteGroup }
}

const DragAndDrop = () => {
  const { depDeleteGroup, columns, setColumns, setDepDeleteGroup } =
    useDragAndDrop()
  const { data, loading, isSuccess } = useFetch({
    func: () => api.get('/device?perPage=999'),
    deps: depDeleteGroup
  })

  useEffect(() => {
    addGroupColumns(columns)
    setItemsOnColumns({ isSuccess, data, setColumns })
  }, [data])

  const onFinish = async (values: { name: string }) =>
    await submitNewGroup(values, setColumns, columns)

  if (!isSuccess) return <></>
  return (
    <Skeleton active loading={loading && !data}>
      <GroupForm onFinish={onFinish} />
      <Row gutter={[16, 16]}>
        <Context onDragEnd={result => onDragEnd(result, columns, setColumns)}>
          <Columns
            {...{ columns, isSuccess, data, setColumns, setDepDeleteGroup }}
          />
        </Context>
      </Row>
    </Skeleton>
  )
}

export default DragAndDrop
