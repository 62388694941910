import { CloseOutlined } from '@ant-design/icons'
import { Form, Row, Col, Button, Card, message, Popconfirm } from 'antd'
import Icon from '@components/common/Icon'
import { api } from '@helpers/api'
import TextInput from '@components/common/TextInput'
import { useTranslation } from 'react-i18next'
import { rulesUser } from '@helpers/rulesUser'
const inputFields = ['name', 'email', 'password', 'password_confirmation']
import type { ICompany } from '@/types/ICompany'
import type { IUser } from '@/types/IUser'

interface FormUserProps {
  data: ICompany | undefined
  action: string
}

const DeleteUserButton = ({ user, remove }: { user: IUser; remove: any }) => {
  const { t } = useTranslation()
  const deleteUser = async () => {
    await api
      .delete(`user/${user.uuid}`)
      .then(() => message.success(`${t('SUCESSDELETEUSER')}: ${user.name}`, 3))
      .catch(() => message.error(t('ERRORDELETEUSER'), 5))
  }

  return (
    <Popconfirm
      description={t('AREYOUSUREDELETEUSER')}
      title={t('DELETEUSER')}
      onConfirm={async () => {
        await deleteUser()
        remove()
      }}
    >
      <CloseOutlined data-cy="delete-exist-user" />
    </Popconfirm>
  )
}

const FormUser = ({ data, action }: FormUserProps) => {
  const { t } = useTranslation()
  const fieldRules: any = rulesUser(t)
  return (
    <Col span={24}>
      <Form.List name="users">
        {(fields, { add, remove }) => (
          <>
            <Row gutter={[8, 8]} style={{ marginBottom: '10px' }}>
              {fields.map(({ key, name, ...restField }) => (
                <Col key={key} xs={{ span: 24 }} xl={{ span: 12 }}>
                  <Card
                    key={key}
                    size="small"
                    title={`${t('USER')}${name + 1}`}
                    extra={
                      data?.users[key] ? (
                        <DeleteUserButton
                          key={key}
                          user={data?.users[key]}
                          remove={() => remove(name)}
                        />
                      ) : (
                        <CloseOutlined
                          data-cy={`delete-user-${name}`}
                          onClick={() => {
                            remove(name)
                          }}
                        />
                      )
                    }
                  >
                    <Row gutter={[8, 8]}>
                      {inputFields.map(item => (
                        <Col key={item} xs={{ span: 12 }} xl={{ span: 12 }}>
                          <TextInput
                            {...restField}
                            key={`${key}_${item}`}
                            name={[name, item]}
                            label={t(item.toUpperCase())}
                            rules={[
                              {
                                required: action === 'create',
                                message: t('requiredItem')
                              },
                              fieldRules[item]
                            ]}
                          />
                        </Col>
                      ))}
                    </Row>
                  </Card>
                </Col>
              ))}
            </Row>
            <Form.Item>
              <Button type="dashed" onClick={() => add()} block>
                {t('ADDUSER')}
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Col>
  )
}

export default FormUser
