import { useEffect, PropsWithChildren, useState, useContext } from 'react'
import { ModalContext } from '@contexts/ModalContext'
import { api } from '@helpers/api'
import Feedback from '@components/User/Feedback'
import useModal from '@hooks/useModal'
import { UserContext } from '@contexts/UserContext'

const FeedbackContext = ({ children }: PropsWithChildren) => {
  const [nps, setNps] = useState([])
  const context = useContext(UserContext)
  const { open, onOpen, onCancel } = useModal()

  useEffect(() => {
    setTimeout(() => {
      if (context?.islogged)
        api.get('/nps').then(e => {
          e.data.data.length ? (onOpen(), setNps(e.data.data)) : null
        })
    }, 200)
  }, [])

  return (
    <ModalContext.Provider value={{ open, onOpen, onCancel }}>
      <Feedback {...{ open, onOpen, onCancel, nps }} />
      {children}
    </ModalContext.Provider>
  )
}

export default FeedbackContext
