import omit from 'lodash/omit'
import { useTranslation } from 'react-i18next'
import { api } from '@helpers/api'
import { Form, Row, Col, Input, DatePicker, FormInstance } from 'antd'
import type { ICreateForm } from '@/types/ICreateForm'
import TextInput from '@common/TextInput'
import { ICompany } from '@/types/ICompany'
import { cpf, cnpj } from 'cpf-cnpj-validator'
import PhonesForm from '@common/PhonesForm'
import EmailsForm from '@common/EmailsForm'
import FormUser from '../FormUser'
import useCompanyForm from '@hooks/useCompanyForm'

import dayjs from 'dayjs'

const inputFields = [
  'name',
  'fantasy_name',
  'address',
  'city',
  'estate',
  'country',
  'origin_reason',
  'alias',
  'obs',
  'obs_relationship',
  'software',
  'sector'
]

interface CreateCompanyProps extends ICreateForm {
  data: ICompany | undefined
}

const setInititalValues = (data: ICompany | undefined) => {
  if (data?.uuid) {
    const filteredUsers = data?.users.map(user =>
      omit(user, ['entity', 'companies', 'preferences', 'sectors'])
    )
    return {
      ...data,
      users: filteredUsers,
      migration_date: data.migration_date
        ? dayjs(data.migration_date)
        : undefined
    }
  }
}

const FormCompany = ({
  onClose,
  action,
  data,
  ...props
}: CreateCompanyProps) => {
  const close = () => {
    form.resetFields()
    onClose()
  }
  const { onFinish, disabled, contextHolder } = useCompanyForm(
    action,
    close,
    data
  )
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const initialValues = setInititalValues(data)
  const fieldrules = ['name', 'address', 'city', 'estate', 'origin_reason']
  return (
    <Form
      {...props}
      form={form}
      disabled={disabled}
      layout="vertical"
      initialValues={initialValues}
      onFinish={onFinish}
    >
      {contextHolder}
      <Row gutter={12}>
        <CEPInput form={form} />
        <DocnumInput />
        {inputFields.map(item => {
          const isFieldRequired = fieldrules.includes(item)

          return (
            <Col key={item} xs={{ span: 24 }} xl={{ span: 8 }}>
              <TextInput
                key={item}
                label={t(item.toUpperCase())}
                name={item}
                rules={[
                  {
                    required: isFieldRequired,
                    message: t('requiredItem')
                  }
                ]}
              />
            </Col>
          )
        })}
        <Col xs={{ span: 12 }} xl={{ span: 8 }} lg={{ span: 8 }}>
          <Form.Item
            name="migration_date"
            label={t('MIGRATION_DATE')}
            rules={[{ required: true, message: t('requiredItem') }]}
          >
            <DatePicker style={{ width: '100%' }} format="YYYY-MM-DD HH:mm" />
          </Form.Item>
        </Col>
        <PhonesForm />
        <EmailsForm />
        <FormUser action={action} data={data} />
      </Row>
    </Form>
  )
}

const CEPInput = ({ form }: { form: FormInstance }) => {
  const { t } = useTranslation()

  const getCEP = async (cep: string) => {
    const response = await api.get(`https://viacep.com.br/ws/${cep}/json/`)
    const data = response.data
    if (data) {
      form.setFieldsValue({
        address: data.logradouro,
        city: data.localidade,
        estate: data.uf,
        country: 'Brasil'
      })
    }
  }

  return (
    <Col xs={{ span: 24 }} xl={{ span: 8 }}>
      <Form.Item
        name="cep"
        label="CEP"
        rules={[{ required: true, message: t('requiredItem') }]}
      >
        <Input onBlur={e => getCEP(e.target.value)} />
      </Form.Item>
    </Col>
  )
}

const DocnumInput = () => {
  const { t } = useTranslation()
  return (
    <Col xs={{ span: 24 }} xl={{ span: 8 }}>
      <TextInput
        label="Cpf/Cnpj"
        name="docnum"
        rules={[
          { required: true, message: t('requiredItem') },
          {
            validator(_, value) {
              if (cpf.isValid(value) || cnpj.isValid(value)) {
                return Promise.resolve()
              } else {
                return Promise.reject(t('INVALID_DOCNUM'))
              }
            }
          }
        ]}
      />
    </Col>
  )
}

export default FormCompany
