import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { Select, Switch, Row, FloatButton } from 'antd'
import { SUserOption } from '@components/Ticket/SUserSelect'
import { ISector } from '@/types/ISector'
import { useLocation } from 'react-router-dom'
import ModalReportTickets from '@components/Ticket/ModalReport'
import useSearchParams from '@hooks/useSearchParams'
import ClearFilterButton from '@components/common/ClearFilterButton'
import { SearchParams } from '@/types/SearchParams'
import { IService } from '@/types/IService'
import useModal from '@hooks/useModal'
import { api } from '@helpers/api'
import SubTitle from '@common/SubTitle'
import type { ISUser } from '@/types/IUser'
import { useTranslation } from 'react-i18next'
import Icon from '@components/common/Icon'
import { useUser } from '@contexts/UserContext'
import useDebounce from '@hooks/useDebounce'
const { Option } = Select
const queryItems = async (search = '', url: string) =>
  api.get(`${url}?search=${search}`).then(e => e.data.data)

const searchSusers = async (search = '') => queryItems(search, 'susers')
const searchSector = async (search = '') => queryItems(search, 'sector')
const searchServices = async (search = '') =>
  queryItems(search, 'ticket/service')

const defaultProps = {
  showSearch: true,
  allowClear: true,
  filterOption: false
}

const onClearByName = (
  name: string,
  setValue: Dispatch<SetStateAction<string[] | []>>,
  params: SearchParams
) => {
  setValue([])
  params.removeItem(name)
}

const onChangeByName = (
  name: string,
  values: Array<string>,
  params: SearchParams,
  setValue: Dispatch<SetStateAction<string[] | []>>,
  onClear: () => void
) => {
  if (values?.length) {
    params.toogleItem(name, values.toString())
    setValue(values)
    return
  }
  onClear()
}

type UseTicketSelect = {
  name: string
  func: (search: string) => Promise<any>
}

const useTicketSelect = ({ name, func }: UseTicketSelect) => {
  const initRef = useRef(true)
  const location = useLocation()
  const params = useSearchParams(true)
  let defaultValue = params.getItem(name)?.split(',')
  const [options, setOptions] = useState([])
  const [value, setValue] = useState<string[] | []>([])
  const [search, setSearch] = useState('')
  const { debouncedValue } = useDebounce(search)

  const onSearch = (search = '') => setSearch(search)
  const onFocus = () => onSearch('')
  const onClear = () => onClearByName(name, setValue, params)
  const onChange = (values: Array<string>) =>
    onChangeByName(name, values, params, setValue, onClear)

  useEffect(() => {
    if (!initRef.current) func(search).then(e => setOptions(e))
    initRef.current = false
  }, [debouncedValue])
  useEffect(() => {
    const items = params.getItem(name)
    if (items) {
      setValue(items.split(','))
      onSearch(items)
      return
    }
    setValue([])
  }, [location.search])

  return { onSearch, onClear, onFocus, onChange, options, defaultValue, value }
}

const SUsersSelect = () => {
  const { options, onSearch, onFocus, onChange, onClear, defaultValue, value } =
    useTicketSelect({ name: 'assignedTo', func: searchSusers })
  const { t } = useTranslation()

  return (
    <Select
      {...defaultProps}
      {...{ onSearch, onClear, onChange, onFocus, defaultValue, value }}
      mode="multiple"
      style={{ width: '100%', height: 'auto' }}
      placeholder={t('ASSIGNEDTO')}
      data-cy="filterassignedTo"
    >
      {options
        ? options.map((e: ISUser) => (
            <Option key={e.uuid} label={e.name} value={e.uuid}>
              <SUserOption suser={e} />
            </Option>
          ))
        : null}
    </Select>
  )
}

const ServiceSelect = () => {
  const { t } = useTranslation()
  const { options, ...serviceProps } = useTicketSelect({
    name: 'services',
    func: searchServices
  })
  return (
    <Select
      mode="multiple"
      style={{ width: '100%' }}
      {...defaultProps}
      {...serviceProps}
      placeholder={t('SERVICE')}
    >
      {Array.isArray(options)
        ? options.map((e: IService) => (
            <Option key={e.uuid} value={e.uuid} children={e.name} />
          ))
        : null}
    </Select>
  )
}

const AssignedSectorsSelect = () => {
  const { t } = useTranslation()
  const { options, ...selectProps } = useTicketSelect({
    name: 'assignedSectors',
    func: searchSector
  })

  return (
    <Select
      {...defaultProps}
      {...selectProps}
      mode="multiple"
      placeholder={t('sector')}
      style={{ width: '100%' }}
      data-cy="filterSectors"
    >
      {options && Array.isArray(options)
        ? options.map((item: ISector) => (
            <Option key={item.slug} label={item.name} value={item.slug}>
              {item.name}
            </Option>
          ))
        : null}
    </Select>
  )
}

const useAssignedUserSwitch = () => {
  const [isAssigned, setIsAssigned] = useState(false)
  const params = useSearchParams(true)
  const switchRef = useRef<boolean>(false)
  const { user } = useUser()
  const location = useLocation()

  useEffect(() => {
    if (!switchRef.current) {
      const assignedTo = params.getItem('assignedTo')
      assignedTo === user.uuid ? setIsAssigned(true) : setIsAssigned(false)
    }
    switchRef.current = false
  }, [location.search])

  const onChange = () => {
    switchRef.current = true
    const assignedTo = params.getItem('assignedTo')
    if (assignedTo === user.uuid) {
      params.removeItem('assignedTo')
      setIsAssigned(false)
    } else {
      params.setItem('assignedTo', user.uuid)
      setIsAssigned(true)
    }
  }
  return { checked: isAssigned, onChange }
}

const AssignedUserSwitch = () => {
  const { t } = useTranslation()
  const switchProps = useAssignedUserSwitch()
  return (
    <Row justify={'start'} gutter={8}>
      <Switch
        {...switchProps}
        checkedChildren={<Icon name="fal fa-user" color="white" />}
        unCheckedChildren={<Icon name="fal fa-users" color="white" />}
      />
      <SubTitle span={18} level={5}>
        {t('ASSIGNEDTOME')}
      </SubTitle>
    </Row>
  )
}

export const ReportButton = () => {
  const { t } = useTranslation()
  const { open, onOpen, onCancel } = useModal()
  return (
    <>
      <ModalReportTickets
        title={t('EXPORTTICKETS')}
        open={open}
        onCancel={onCancel}
      />
      <FloatButton
        data-cy="open-report-ticket"
        style={{ right: 50, zIndex: 1 }}
        shape="square"
        onClick={onOpen}
        icon={<Icon name="fa-light fa-file-export" />}
      />
    </>
  )
}

export const arrFilterTickets = [
  { span: 4, children: <AssignedUserSwitch /> },
  { span: 4, children: <SUsersSelect /> },
  { span: 5, children: <AssignedSectorsSelect /> },
  { span: 6, children: <ServiceSelect /> },
  { span: 2, children: <ClearFilterButton uri="/tickets" item="tickets" /> }
]
