import { Row, Tabs, Col, DatePicker, Select, Space, Form } from 'antd'
import Title from '@common/Title'
import { renderingTabFilter } from '@components/Backup/Table'
import { TabsProps } from 'antd'
import { useTranslation } from 'react-i18next'
import DeviceFilterSelect from '@components/common/DeviceFilterSelect'
import {
  Location,
  NavigateFunction,
  useLocation,
  useNavigate
} from 'react-router-dom'
import useSearchParams from '@hooks/useSearchParams'
import dayjs, { Dayjs } from 'dayjs'
import IconButton from '@components/common/IconButton'
import SearchByQueryInput from '@components/common/SearchByQueryInput'
import { useUser } from '@contexts/UserContext'
import { CompanySelect } from '@components/common/CompanySelect'
import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useState
} from 'react'
import { SearchParams } from '@/types/SearchParams'

type ParamsSelectProps = { options: Array<string>; param: string }

const statesBackup = ['InProgress', 'Completed', 'Pending']
const resultBackup = ['Failed', 'Warning', 'Success']
const typeRespoint = ['Increment', 'Full']
const { RangePicker } = DatePicker
const { Option } = Select

const tabProps: TabsProps = {
  style: { width: '100%' },
  destroyInactiveTabPane: true,
  defaultActiveKey: '1',
  type: 'card',
  size: 'large'
}

const formatDate = (date: any) => {
  return dayjs(date).format()
}

const updateURL = (
  queryParams: {},
  navigate: NavigateFunction,
  location: Location
) => {
  const currentParams = new URLSearchParams(location.search)
  for (const [key, value] of Object.entries(queryParams)) {
    currentParams.set(key, value)
  }
  const newSearchParams = currentParams.toString()
  navigate(`/backups?${newSearchParams}`, { replace: true })
}

const formatParams = (item: any) => {
  let queryParams = { start_at: '', end_at: '' }
  item.map(
    (date: string, index: number) =>
      (queryParams[index === 0 ? 'start_at' : 'end_at'] = formatDate(date))
  )
  return queryParams
}

const onClearTimeRange = (
  navigate: NavigateFunction,
  setValue: Dispatch<SetStateAction<undefined | Array<Dayjs>>>
) => {
  const currentParams = new URLSearchParams(location.search)
  for (const param of ['end_at', 'start_at']) {
    currentParams.delete(param)
  }
  const newSearchParams = currentParams.toString()
  navigate(`/backups?${newSearchParams}`, { replace: true })
  setValue(undefined)
}

const getDefaulValue = (params: SearchParams) => {
  const start_at = params.getItem('start_at')
  const end_at = params.getItem('end_at')
  return start_at && end_at ? [dayjs(start_at), dayjs(end_at)] : undefined
}
const useTimeRange = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const params = useSearchParams()

  const [value, setValue] = useState<Array<Dayjs> | undefined>(() =>
    getDefaulValue(params)
  )

  const onClear = () => onClearTimeRange(navigate, setValue)

  useEffect(() => {
    let query = location.search
    if (!query.includes('start_at') && !query.includes('end_at'))
      setValue(undefined)
  }, [location.search])

  const onChange = (item: any) => {
    if (item?.length === 2) {
      updateURL(formatParams(item), navigate, location)
      setValue(item)
      return
    }
    onClear()
  }
  return { onChange, defaultValue: value, value }
}

const TimeRangePicker = () => {
  const { onChange, defaultValue, value } = useTimeRange()
  const disableDate = (date: Dayjs) => dayjs() <= date
  const { t } = useTranslation()
  return (
    <Col xs={{ span: 24 }} xl={{ span: 6 }}>
      <RangePicker
        format="DD-MM-YYYY HH:mm"
        allowClear
        showTime
        {...{ onChange, value, defaultValue }}
        disabledDate={disableDate}
        style={{ width: '100%' }}
        placeholder={[t('STARTAT'), t('ENDAT')]}
      />
    </Col>
  )
}

const useParamsSelect = (param: string) => {
  const params = useSearchParams()
  const [value, setValue] = useState(params.getItem(param))
  const location = useLocation()
  useEffect(() => {
    !location.search.includes(param)
      ? setValue(undefined)
      : setValue(() => params.getItem(param))
  }, [location.search])

  const onChange = (value: string) => {
    params.setItem(param, value)
    setValue(value)
  }

  const onClear = () => {
    params.removeItem(param)
    setValue(undefined)
  }
  return { value, onChange, onClear, defaultValue: value }
}

const ParamsSelect = ({ options, param }: ParamsSelectProps) => {
  const { t } = useTranslation()
  const paramsSelect = useParamsSelect(param)
  return (
    <Col xs={{ span: 24 }} xl={{ span: 3 }}>
      <Select
        data-cy={`params-select-${param}`}
        {...paramsSelect}
        placeholder={`${t('FILTERBY')} ${t(param.toUpperCase())}`}
        allowClear
        style={{ width: '100%' }}
      >
        {options.map(item => (
          <Option key={item} value={item}>
            {t(item)}
          </Option>
        ))}
      </Select>
    </Col>
  )
}

const ClearButton = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const onClick = () => {
    navigate('/backups')
  }

  return (
    <IconButton
      data-cy="clear-filters-backup"
      name="fa-light fa-trash"
      onClick={onClick}
      block
      htmlType="reset"
    >
      {t('CLEARFILTERS')}
    </IconButton>
  )
}

const ResponsiveCol = (props: any) => {
  return <Col xs={{ span: 24 }} {...props} xl={props.xl || { span: 4 }} />
}

const GenericFilterItems = ({ children }: PropsWithChildren) => {
  const { user } = useUser()
  return (
    <>
      <Row gutter={[16, 8]}>
        <ResponsiveCol>
          <SearchByQueryInput span={24} />
        </ResponsiveCol>
        <ResponsiveCol>
          <DeviceFilterSelect />
        </ResponsiveCol>
        {children}
        <TimeRangePicker />
        {user.level > 1 ? <CompanySelect span={4} /> : null}
      </Row>
      <Row>
        <ResponsiveCol xl={24}>
          <ClearButton />
        </ResponsiveCol>
      </Row>
    </>
  )
}

const FilterBackup = () => {
  return (
    <>
      <ParamsSelect options={statesBackup} param="state" />
      <ParamsSelect options={resultBackup} param="result" />
    </>
  )
}

const FilterRespoints = () => {
  return <ParamsSelect options={typeRespoint} param="type" />
}

const Filter = ({ tab }: { tab: number }) => {
  return (
    <GenericFilterItems>
      {tab === 1 ? <FilterBackup /> : <FilterRespoints />}
    </GenericFilterItems>
  )
}

const Backup = () => {
  const renderingWithTab = renderingTabFilter()
  const [tab, setTab] = useState(1)
  const { t } = useTranslation()
  return (
    <Row style={{ width: '100%' }} gutter={[4, 1]}>
      <Col span={24}>
        <Title name={t('BACKUPS')} />
        <Form>
          <Row
            style={{ width: '100%' }}
            gutter={[4, 4]}
            justify="space-between"
          >
            <Filter tab={tab} />
          </Row>
        </Form>
      </Col>

      <Tabs
        onChange={key => setTab(Number(key))}
        {...tabProps}
        items={renderingWithTab}
      />
    </Row>
  )
}

export default Backup
