import { useState } from 'react'
import {
  Form,
  InputNumber,
  Row,
  Col,
  Slider,
  Divider,
  Radio,
  Button,
  Select,
  FormInstance,
  RadioChangeEvent,
  Input
} from 'antd'
import { useTranslation } from 'react-i18next'
import type { IItem, IItemOrderRequest } from '@/types/IOrder'
import IconButton from '@components/common/IconButton'

interface OrderRequestProps {
  name: number
  form: FormInstance
}

interface OrderItemProps {
  orderRequestKey: number
  data: IItemOrderRequest
}

const FormItemsOrderRequest = ({ name, form }: OrderRequestProps) => {
  const data = form.getFieldsValue()
  return (
    <Form.List name={[name, 'order_request', 'items']}>
      {fields => {
        return (
          <>
            {fields.map(({ name: orderRequestKey }) => (
              <>
                {data?.items ? (
                  <OrderItem
                    orderRequestKey={orderRequestKey}
                    data={data.items[name].order_request.items[orderRequestKey]}
                  />
                ) : null}
              </>
            ))}
          </>
        )
      }}
    </Form.List>
  )
}

const OrderItem = ({ orderRequestKey, data }: OrderItemProps) => {
  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        {data.item.children?.length > 0 ? (
          <ChildrenItem data={data} orderRequestKey={orderRequestKey} />
        ) : (
          <SliderItem
            formName={[orderRequestKey, 'current']}
            data={data.item}
          />
        )}
      </Col>
      <Divider />
    </Row>
  )
}

const SliderItem = ({ formName, data }: any) => {
  const { t } = useTranslation()
  return (
    <Row>
      <Col span={18}>
        <Form.Item name={formName} label={data.item} required={data.required}>
          <Slider
            marks={{
              [data.min]: `${data.min}`,
              [data.max]: `${data.max}`
            }}
            min={data.min}
            max={data.max}
          />
        </Form.Item>
      </Col>
      <Col span={5} offset={1}>
        <Form.Item
          name={formName}
          label={' '}
          rules={[{ required: true, message: t('requiredItem') }]}
        >
          <InputNumber addonAfter={data?.postfix} />
        </Form.Item>
      </Col>
    </Row>
  )
}

const ChildrenItem = ({ data, orderRequestKey }: OrderItemProps) => {
  const { t } = useTranslation()
  const transformToSelectOptions = () => {
    return data.item.children.map((item: IItem) => ({
      value: item.uuid,
      label: item.item
    }))
  }
  const [selectedChildren, setSelectedChildren] = useState<IItem[]>(
    data?.children?.items?.map(item => item?.item) || []
  )

  const handleRadioChange = (e: RadioChangeEvent, name: number) => {
    const value = e.target.value
    const selectedChildItem = data.item.children.find(
      (child: IItem) => child.uuid === value
    )
    const newSelectedChildren = [...selectedChildren]
    newSelectedChildren[name] = selectedChildItem
    setSelectedChildren(newSelectedChildren)
  }
  return (
    <>
      {data.item.type === 'LIST' ? (
        <>
          <span> {data.item.item}</span>
          <Form.List name={[orderRequestKey, 'children', 'items']}>
            {(fields, { add, remove }) => (
              <>
                <Row gutter={[8, 8]} style={{ marginBottom: '10px' }}>
                  {fields.map(({ key, name }) => {
                    return (
                      <Col key={key} span={24}>
                        <Row gutter={[16, 16]}>
                          <Form.Item name={[name, 'item', 'uuid']}>
                            <Radio.Group
                              key={name}
                              optionType="button"
                              buttonStyle="solid"
                              onChange={e => handleRadioChange(e, name)}
                              style={{ marginRight: '20px' }}
                            >
                              {data.item.children.map((children: IItem) => (
                                <Radio
                                  key={children.uuid}
                                  value={children.uuid}
                                >
                                  {children.item}
                                </Radio>
                              ))}
                            </Radio.Group>
                          </Form.Item>
                          {selectedChildren[name] && (
                            <Form.Item name={[name, 'current']}>
                              <InputNumber
                                addonAfter={selectedChildren[name]?.postfix}
                                style={{ marginRight: '20px' }}
                                min={selectedChildren[name]?.min}
                                max={selectedChildren[name]?.max}
                              />
                            </Form.Item>
                          )}
                          <IconButton
                            name="fa-light fa-trash"
                            onClick={() => remove(name)}
                            data-cy={`delete-item-${name}`}
                          />
                        </Row>
                      </Col>
                    )
                  })}
                </Row>
                <Form.Item>
                  <Button
                    data-cy="add-item"
                    type="dashed"
                    onClick={() => add()}
                    block
                  >
                    {t('ADD_ITEM')}
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </>
      ) : (
        <Form.Item
          name={[orderRequestKey, 'children', 'items', 0, 'item', 'uuid']}
          label={data.item.item}
          required={data.item.required}
        >
          <Select options={transformToSelectOptions()} />
        </Form.Item>
      )}
    </>
  )
}

export default FormItemsOrderRequest
