import { useTranslation } from 'react-i18next'
import { Form, Input } from 'antd'
import { MinusCircleOutlined } from '@ant-design/icons'
import { IFormList } from '@/types/IFormList'
import FormList from '../FormList'

const PhonesForm = () => {
  return (
    <FormList label="ADDPHONE" name="phones">
      {props => <PhoneNumber {...props} />}
    </FormList>
  )
}

const PhoneNumber = ({ field, remove, index }: IFormList) => {
  const { t } = useTranslation()

  return (
    <Form.Item label={index === 0 ? t('PHONE') : null} key={field.key}>
      <Form.Item
        {...field}
        rules={[
          {
            pattern: /\+?\d*$/,
            message: t('Please enter only numbers')
          }
        ]}
        noStyle
      >
        <Input
          placeholder="+55 11 11111-1111"
          style={{ width: '70%', marginRight: '10px' }}
        />
      </Form.Item>
      <MinusCircleOutlined
        onClick={() => remove(field.name)}
        className="dynamic-delete-button"
      />
    </Form.Item>
  )
}

export default PhonesForm
