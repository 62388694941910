import { useState } from 'react'
import { Form, FormInstance, Row, Col, Button, Card, Radio } from 'antd'
import useForm from '@hooks/useForm'
import type { IOrder, IItemOrderRequest } from '@/types/IOrder'
import { SearchSelect } from '@components/User/Form'
import { CloseOutlined } from '@ant-design/icons'
import { api } from '@helpers/api'
import { useTranslation } from 'react-i18next'
import FormItemsOrderRequest from '../FormItemsOrderRequest'
import { useGenericContext } from '@hooks/useGenericContext'
import { OrderContext } from '@contexts/OrderContext'

interface FormOrderProps {
  data: IOrder | undefined
}

const initialValues = (data: Partial<IOrder> | undefined) => {
  if (data) {
    return {
      ...data,
      company: data?.company?.uuid
    }
  }
}

const handleFinish = (values: IOrder, onFinish: (object: any) => void) => {
  const formattedValues = {
    company: values.company,
    status: values.status,
    items: values.items.map(item => ({
      product: item.product.uuid,
      order_request: item.order_request.items.map(formatOrderItem)
    }))
  }
  onFinish(formattedValues)
}

const formatOrderItem = (orderItem: IItemOrderRequest) => ({
  uuid: orderItem.item.uuid,
  current:
    orderItem.children?.items?.length > 0
      ? orderItem.children.items.length
      : orderItem.current,
  children: orderItem.children?.items?.map(item => ({
    uuid: item.item.uuid,
    current: item.current ?? orderItem.current
  }))
})

const FormOrder = ({ data }: FormOrderProps) => {
  const [form] = Form.useForm()
  const { action, numForm, setData, onClose } = useGenericContext(OrderContext)
  const { t } = useTranslation()
  const { onFinish, disabled, contextHolder } = useForm(
    action,
    onClose,
    '/order',
    data?.uuid
  )

  return (
    <Form
      {...{ form, disabled }}
      layout="vertical"
      initialValues={initialValues(data)}
      onFinish={values => handleFinish(values, onFinish)}
      id={numForm}
      onValuesChange={(_, allValues) =>
        setData(prev => ({ ...prev, ...allValues }))
      }
    >
      {contextHolder}
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <SearchSelect
            initialValue={
              data?.company
                ? [{ value: data.company.uuid, label: data.company.name }]
                : []
            }
            require={true}
            name="COMPANY"
            searchUrl="company"
          />
        </Col>
        <Col span={24}>
          <span>{t('PRODUCTS')}</span>
          <FormItems form={form} />
        </Col>
      </Row>
    </Form>
  )
}

const FormItems = ({ form }: { form: FormInstance }) => {
  const { t } = useTranslation()
  const { data } = useGenericContext(OrderContext)
  const [dcName, setDcName] = useState(data?.items)
  const onChange = async (value: string, key: number) => {
    const result = await api.get(`/product/${value}`)
    const { items, ...product } = result.data

    const values = form.getFieldValue('items')
    values[key] = {
      order_request: {
        items: result.data.items.map(item => ({
          item: item,
          current: item.min
        }))
      },
      product: product
    }
    form.setFieldValue('items', values)
    setDcName(values)
  }
  return (
    <Form.List name="items">
      {(fields, { add, remove }) => (
        <>
          <Row gutter={[8, 8]} style={{ marginBottom: '10px' }}>
            {fields.map(({ key, name }) => {
              return (
                <Col xs={24} xl={12} key={key}>
                  <Card
                    style={{ width: '100%' }}
                    type="inner"
                    key={name}
                    size="small"
                    title={`${t('PRODUCT')} ${name + 1}`}
                    extra={<CloseOutlined onClick={() => remove(name)} />}
                  >
                    <Row gutter={[16, 16]}>
                      <Col span={18} xl={18} xs={24}>
                        <SearchSelect
                          require={true}
                          name={[name, 'product', 'uuid']}
                          searchUrl="product"
                          placeholder={t('PRODUCT')}
                          onChange={value => onChange(value, name)}
                          data-cy={`select-product-${name}`}
                        />
                      </Col>
                      <Col xl={{ span: 4, offset: 2 }} xs={24}>
                        {dcName && dcName[name] ? (
                          <Form.Item
                            name={[name, 'datacenter']}
                            label={'Datacenter'}
                          >
                            <Radio.Group
                              optionType="button"
                              buttonStyle="solid"
                            >
                              <Radio>
                                {`${dcName[name]?.product?.dc?.shortname}`}
                              </Radio>
                            </Radio.Group>
                          </Form.Item>
                        ) : null}
                      </Col>
                      <Col span={24}>
                        <FormItemsOrderRequest name={name} form={form} />
                      </Col>
                    </Row>
                  </Card>
                </Col>
              )
            })}
          </Row>
          <Form.Item data-cy="add-items">
            <Button block type="primary" onClick={() => add()}>
              {t('ADD_PRODUCT')}
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  )
}

export default FormOrder
