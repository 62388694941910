import { TableContext } from '@contexts/TableContext'
import { IRequestList } from '@/types/ICollection'
import { ITableContext } from '@/types/ITableCard'
import { useContext } from 'react'

type Item<T> = T & { uuid: string }
const copyObj = (obj: {}) => JSON.parse(JSON.stringify(obj))

const useTableContext = <T>(): ITableContext => {
  const context = useContext(TableContext)

  const add = (newItem: Item<T>) =>
    context?.setData((prevValue: IRequestList<Item<T>>) => ({
      ...prevValue,
      data: [newItem, ...prevValue.data]
    }))

  const update = (uuid: string, newItem: T) =>
    context?.setData((prev: IRequestList<Item<T>>) => ({
      ...copyObj(prev),
      data: prev.data.map((item: Item<T>) =>
        item.uuid === uuid ? newItem : item
      )
    }))

  const remove = (uuid: string) =>
    context?.setData((prev: IRequestList<Item<T>>) => {
      const index = prev.data.findIndex(item => item.uuid === uuid)
      return {
        ...copyObj(prev),
        data: [...prev.data.splice(0, index), ...prev.data.splice(index + 1)]
      }
    })

  return { add, update, remove }
}

export default useTableContext
