import { api } from '@helpers/api'
import { useLocation } from 'react-router-dom'
import { useIntervalFetch } from '@hooks/useFetch'
import { IRequestList } from '@types/ICollection'
import { useUser } from '@contexts/UserContext'

interface ParamsType {
  url: string
  page: number
  perPage: number
  search: string
}

export const queryFetch = async <T>(
  params: ParamsType,
  signal?: AbortSignal
) => {
  const search = decodeURIComponent(params.search).slice(1)
  return await api
    .get<T>(
      `${params.url.includes('?') ? params.url + '&' : params.url + '?'}${
        search ? `${search}&` : ''
      }page=${params.page}&perPage=${params.perPage}`,
      { signal: signal }
    )
    .then(response => response)
}

export const queryListWithPagination = <T>(url: string, page: number) => {
  const { search } = useLocation()
  const { perPage } = useUser()
  const { data, isSuccess, loading, setLoading, setData } = useIntervalFetch<
    IRequestList<T>,
    ParamsType
  >({
    func: queryFetch,
    deps: [page, perPage, search],
    params: { url, page, perPage, search }
  })

  return { data, isSuccess, loading, setLoading, setData }
}
