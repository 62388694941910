import { PropsWithChildren, useEffect, useState } from 'react'
import { Select, Radio, SelectProps, Space, Tag, Col, Form } from 'antd'
import { AutoDeployPoolResponse, Pool } from '@/types/AutoDeploy'
import { AxiosResponse } from 'axios'
import { SearchSelectWithForm } from '@components/common/SearchSelectWithForm'
import useSearchSelect from '@hooks/useSearchSelect'
import { DC } from '@/types/IDevice'
import { api } from '@helpers/api'
import { useTranslation } from 'react-i18next'
import { useGenericContext } from '@hooks/useGenericContext'
import { MessageContext } from '@contexts/MessageContext'
import { handleError } from '@helpers/handleError'
import { PoolContext } from '@contexts/PoolContext'
interface Rack {
  name: string
  uuid: string
}
const { Option } = Select

const getAutodeployInfo = async (url: string) => {
  return api.get(url).then(e => {
    return e.data.data
  })
}

const useCompany = () => {
  const { t } = useTranslation()
  const messageApi = useGenericContext(MessageContext)
  const selectProps = useSearchSelect({
    func: async (search?: string) =>
      await api
        .get(`/company?search=${search || ''}`)
        .then(e => e.data.data)
        .catch(e => handleError(e, messageApi, t('ERRORCALLCOMPANY')))
  })
  return selectProps
}

const CompanySelectFromWrapper = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation()
  return (
    <Form.Item
      {...{
        ...{
          label: t('COMPANY'),
          name: 'company',
          rules: [{ required: true, message: t('requiredItem') }]
        }
      }}
    >
      {children}
    </Form.Item>
  )
}

export const CompanySelect = () => {
  const { options, ...selectProps } = useCompany()
  return (
    <Col span={24}>
      <CompanySelectFromWrapper>
        <Select
          {...selectProps}
          showSearch
          allowClear
          filterOption={false}
          data-cy="company-select-create-server"
        >
          {Array.isArray(options)
            ? options.map(e => (
                <Option key={e.uuid} value={e.uuid} children={e.name} />
              ))
            : null}
        </Select>
      </CompanySelectFromWrapper>
    </Col>
  )
}

export const DCSelect = () => {
  const messageApi = useGenericContext(MessageContext)
  const { options, ...selectProps } = useSearchSelect({
    func: async (search = '') =>
      await getAutodeployInfo(`/dc?search=${search}&autodeploy=1`),
    messageApi
  })
  return (
    <SearchSelectWithForm {...selectProps} name="DC" span={24} allowClear>
      {options && Array.isArray(options)
        ? options.map((option: DC) => (
            <Option key={option.uuid} label={option.name} value={option.uuid}>
              <Space>
                <Tag color="blue-inverse">{option.shortname}</Tag>
                {option.name}
              </Space>
            </Option>
          ))
        : null}
    </SearchSelectWithForm>
  )
}

const useRackSelect = () => {
  const form = Form.useFormInstance()
  const DC = Form.useWatch('DC', form)
  const { t } = useTranslation()
  const messageApi = useGenericContext(MessageContext)
  const [data, setData] = useState<Rack[] | []>([])
  useEffect(() => {
    if (DC) {
      getAutodeployInfo(`/rack?dc=${DC}&autodeploy=1`)
        .then((e: Rack[]) => {
          setData(e)
          !e.length && messageApi.warning(t('THEREISNORACKSINTHISDC'))
        })
        .catch(e => handleError(e, messageApi, t('ERRORCALLRACK')))
    } else {
      setData([])
      form.setFieldValue('rack', undefined)
    }
  }, [DC])
  return data
}

export const RacksSelect = (props: SelectProps) => {
  const { t } = useTranslation()
  const data = useRackSelect()
  return (
    <Col span={24}>
      <Form.Item
        name="rack"
        label="Rack"
        rules={[{ required: true, message: t('requiredItem') }]}
      >
        <Select
          allowClear
          {...props}
          data-cy="rack-select-create-select"
          filterOption={false}
        >
          {data.map(option => (
            <Option key={option.uuid} value={option.uuid}>
              {option.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Col>
  )
}

const checkAvailibilyDisks = (pool: Pool) => {
  const disks = pool.total.disks
  if (disks) {
    const disksTypes = Object.keys(disks)
    const availbilityDisks = disksTypes
      ? disksTypes.filter(type => disks[type] > 0)
      : []
    return availbilityDisks
  }
  return []
}

const useDiskType = () => {
  const form = Form.useFormInstance()
  const [DC, rack] = ['DC', 'rack'].map(e => Form.useWatch(e, form))
  const { setPool } = useGenericContext(PoolContext)
  const messageApi = useGenericContext(MessageContext)
  const [data, setData] = useState<Array<string> | []>([])
  const { t } = useTranslation()
  useEffect(() => {
    if (DC && rack) {
      api
        .get('/autodeploy/pool')
        .then((e: AxiosResponse<AutoDeployPoolResponse>) => {
          const pool = e.data[DC][rack]
          setPool(pool)
          setData(() => {
            return checkAvailibilyDisks(e.data[DC][rack])
          })
          if (!pool.hosts.length)
            messageApi.warning(t('THEREISNOHOSTSINTHISRACK'))
        })
        .catch(e => handleError(e, messageApi, t('POLLCALLERROR')))
    }
  }, [rack])
  return data
}

export const DiskTypeSegmented = () => {
  const data = useDiskType()
  const { t } = useTranslation()
  return (
    <Col span={24}>
      {data.length ? (
        <Form.Item
          name="diskType"
          dependencies={['rack']}
          initialValue={data[0]}
          rules={[{ required: true, message: t('requiredItem') }]}
        >
          <Radio.Group buttonStyle="solid">
            {data.map(e => (
              <Radio.Button key={e} value={e}>
                {e}
              </Radio.Button>
            ))}
          </Radio.Group>
        </Form.Item>
      ) : null}
    </Col>
  )
}
